const team = [
  {
    username: 'portodianna',
    image: '/assets/images/team/pet.png',
    linkedin: 'https://www.linkedin.com/in/lorenzopetrangeli/',
    role: 'Chief Executive Officer',
  },

  {
    username: 'gcazzaniga.eth',
    image: '/assets/images/team/gcazzaniga.png',
    linkedin: 'https://www.linkedin.com/in/tommaso-paulon-86438013a/',
    twitter: 'https://twitter.com/goldmansucks_',
    role: 'Chief Technology Officer',
  },

  {
    username: 'uryon.eth',
    image: '/assets/images/team/Uryon.png',
    linkedin: 'https://www.linkedin.com/in/matteo-poli-b5b99512b/',
    twitter: 'https://twitter.com/MatteoPoli15',
    role: 'Chief Operating Officer',
  },

  {
    username: 'Nanni',
    image: '/assets/images/team/nanni.png',
    role: 'Game Lead Engineer',
  },

  {
    username: 'Boyoz',
    image: '/assets/images/team/arda.png',
    role: 'Game Developer',
    linkedin: 'https://www.linkedin.com/in/ardaozler/',
  },

  {
    username: 'iltumio',
    image: '/assets/images/team/iltumio.png',
    linkedin: 'https://www.linkedin.com/in/manuel-tumiati/',
    website: 'https://iltumio.dev',
    role: 'Scientific Advisor',
  },

  {
    username: 'Bruschi',
    image: '/assets/images/team/bruschi.png',
    linkedin: 'https://www.linkedin.com/in/francesco-bruschi-5ab1b15/',
    role: 'Scientific Advisor',
  },

  {
    username: 'Vins',
    image: '/assets/images/team/vins.png',
    linkedin: 'https://www.linkedin.com/in/vincenzo-rana/',
    role: 'Startup Mentor',
  },

  {
    username: 'Grev',
    image: '/assets/images/team/grev.png',
    linkedin: 'https://www.linkedin.com/in/gabriele-licheri-182b1a110/',
    dribbble: 'https://dribbble.com/grevz',
    instagram: 'https://www.instagram.com/gr3vz/',
    role: 'UX/UI Lead',
  },

  {
    username: 'AB Pokerista',
    image: '/assets/images/team/pb.png',
    instagram: 'https://www.instagram.com/porkabestia/?hl=it',
    role: 'Lead Artist',
  },

  {
    username: 'The Donatz',
    image: '/assets/images/team/dona.png',
    instagram: 'https://www.instagram.com/thedonatz/?hl=it',
    role: 'Artist',
  },

  {
    username: 'Gioy',
    image: '/assets/images/team/gioy.png',
    instagram: 'https://www.instagram.com/gioiallievi/',
    linkedin: 'https://www.linkedin.com/in/gioia-allievi-b5854118b/',
    artstation: 'https://www.artstation.com/gioy',
    role: 'Artist',
  },

  {
    username: 'MC Shinbone',
    image: '/assets/images/team/tspanio.png',
    role: 'Beatmaker',
  },
  
  {
    username: 'Terpi',
    image: '/assets/images/team/pietro.png',
    role: 'Chief Marketing Officer',
    linkedin: 'https://www.linkedin.com/in/pietro-parodi-8b6432167/'
  },

  {
    username: 'Hardin Finch',
    image: '/assets/images/team/hardin.png',
    twitter: 'https://twitter.com/criptofinch',
    website: 'https://medium.com/@hardin.finch',
    role: 'Community Manager',
  },
]

export default team
