import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../components/Containers/Card'
import PrimaryButton from '../components/Inputs/PrimaryButton'
import Subtitle from '../components/Typography/Subtitle'
import Title from '../components/Typography/Title'
import { useRouter } from 'next/router'
import ProjectCard from '../components/Containers/ProjectCard'
import SectionEndCurvy from '../components/SVG/SectionEndCurvy'
import useMediaQuery from '../hooks/useMediaQueriees'
import Divider from '../components/Divider'

interface IProjects {}

const Projects: React.FC<IProjects> = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const isMobile = useMediaQuery(1024)

  const renderAvailable = () => {
    return (
      <Card className='bg-green box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.available')}
        </h2>
      </Card>
    )
  }

  const renderSoon = () => {
    return (
      <Card className='bg-cyan box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.soon')}
        </h2>
      </Card>
    )
  }

  const renderSoldOut = () => {
    return (
      <Card className='bg-red box-shadow-sm self-start mt-6'>
        <h2 className='text-white px-4 py-3 font-bold text-md lg:text-2xl'>
          {t('projects.soldOut')}
        </h2>
      </Card>
    )
  }

  return (
    <div className='relative'>
      <Divider
        fillColor='#FFFFFF11'
        className='absolute bottom-[30%] lg:bottom-0'
      />
      <SectionEndCurvy />
      <div
        className='container mx-auto flex flex-col justify-start items-center px-4 pb-10'
        id='projects'
      >
        <div className='-mt-16 lg:-mt-40 2xl:-mt-80'>
          <Title label={t('projects.title')} />
        </div>
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-orange'
          imagePath='/assets/images/projects/modolls.png'
          title={t('projects.dolls.title')}
          description={t('projects.dolls.description')}
          cta={t('projects.dolls.cta')}
          onClick={() => {
            open('https://opensea.io/collection/social-modolls')
          }}
          badge={renderSoldOut}
        />
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-violet'
          imagePath='/assets/images/projects/AOF.png'
          title={t('projects.aof.title')}
          description={t('projects.aof.description')}
          cta={t('projects.dolls.cta')}
          onClick={() => {
            router.push('/aof')
          }}
          badge={renderAvailable}
          reverse
        />
        <div className='mt-20'></div>
        <ProjectCard
          bgColor='bg-orange'
          imagePath='/assets/images/projects/cataclysm.png'
          title={t('projects.cataclysm.title')}
          description={t('projects.cataclysm.description')}
          cta={t('projects.dolls.cta')}
          onClick={() => {
            open('https://cataclysm-game.com')
          }}
          badge={renderSoon}
        />
      </div>
    </div>
  )
}

export default Projects
