import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa'
import { SiTelegram } from 'react-icons/si'
import React from 'react'
import colors from '../utils/colors'

interface IFooter {
  hasSticky?: boolean
}

const Footer: React.FC<IFooter> = ({ hasSticky = false }) => {
  return (
    <div className={`bg-gray ${hasSticky ? 'pb-40' : ''}`}>
      <div className='container mx-auto flex-col justify-center align-middle flex px-10 h-full'>
        <div className='flex-row justify-between items-center flex'>
          <div className='flex-col justify-start items-start flex pb-10'>
            <div className='flex-row justify-start items-center flex'>
              <FaDiscord
                color={colors.white}
                onClick={() => {
                  open('https://discord.gg/acUsZMBBcR')
                }}
                size={'3vh'}
                className='cursor-pointer'
                title='Discord'
              />
              <FaInstagram
                color={colors.white}
                onClick={() => {
                  open('https://www.instagram.com/nft_factory_official/?hl=it')
                }}
                size={'3vh'}
                className='ml-5 cursor-pointer'
                title='Twitter'
              />

              <FaTwitter
                color={colors.white}
                onClick={() => {
                  open('https://twitter.com/NFTFactoryOG')
                }}
                size={'3vh'}
                className='ml-5 cursor-pointer'
                title='Instagram'
              />

              <SiTelegram
                color={colors.white}
                onClick={() => {
                  open('https://t.me/NFTFactoryANNOUNCEMENTS')
                }}
                size={'3vh'}
                className='ml-5 cursor-pointer'
                title='Telegram'
              />
            </div>
            <h2 className='text-white text-xs mt-4'>
              Copyright © 2022 - 2023 NFT Factory All Rights Reserved
            </h2>
            <h2 className='text-white text-xs mt-4'>NFT Factory SRL</h2>
            <h2 className='text-white text-xs mt-2'>Via Santa Monica 1</h2>
            <h2 className='text-white text-xs mt-2'>
              20162 Milano (MI) - Italia
            </h2>
            <h2 className='text-white text-xs mt-2'>ciao@nft-factory.club</h2>
            <h2 className='text-white text-xs mt-2'>
              amministrazione@nft-factory.club
            </h2>
            <h2 className='text-white text-xs mt-2'>P. IVA - 12524830960</h2>
            <h2 className='text-white text-xs mt-2'>SDI X2PH38J</h2>
          </div>
          <div className='flex-col justify-start items-start flex'>
            <a
              className='text-white font-semibold cursor-pointer antialiased'
              href='/#home'
            >
              Home
            </a>
            <a
              className='text-white font-semibold cursor-pointer antialiased'
              href='/#manifesto'
            >
              Manifesto
            </a>
            <a
              className='text-white font-semibold cursor-pointer antialiased'
              href='/#projects'
            >
              Projects
            </a>
            <a
              className='text-white font-semibold cursor-pointer antialiased'
              href='/#services'
            >
              Services
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
